import { LocaleEnum } from '@systemeio/ui-shared'

export const getEmailStatusLink = (locale?: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.SPANISH: {
      return 'https://help-es.systeme.io/article/886-buenas-practicas-para-mejorar-la-capacidad-de-entrega-de-tus-correos-electronicos'
    }
    case LocaleEnum.PORTUGUESE: {
      return 'https://help-pt.systeme.io/article/735-boas-praticas-para-entregabilidade'
    }
    default: {
      return 'https://help.systeme.io/article/363-good-practices-for-deliverability'
    }
  }
}
