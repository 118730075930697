import { Breadcrumbs, BreadcrumbsData } from '@systemeio/ui-shared'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'

interface BreadcrumbsProps {
  data: BreadcrumbsData[]
  isFetching?: boolean
}

const BreadcrumbsDashboard = ({ data, isFetching }: BreadcrumbsProps) => (
  <Breadcrumbs
    data={data}
    isFetching={isFetching}
    CustomBreadcrumbLinkItem={({ children, href }: { children: React.ReactNode; href: string }) => (
      <LinkWithoutPrefetch href={href}>{children}</LinkWithoutPrefetch>
    )}
  />
)

export default BreadcrumbsDashboard
