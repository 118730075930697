import { FormInput } from '@systemeio/ui-shared'
import React from 'react'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import { DisclosureBox } from 'shared/components/disclosure-box'
import { SectionMessage } from 'shared/components/section-message'
import SenderEmailSelect from 'shared/components/sender-email-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { isBlacklistEmailDomain } from 'shared/utils/is-blacklist-email-domain'
import { twJoin } from 'tailwind-merge'
import { DashboardMailEditorCommonProps, ExtendedState } from '../types/common'

export interface EmailSettingsBlockProps<T extends ExtendedState>
  extends Pick<
    DashboardMailEditorCommonProps<T>,
    'isPreFetching' | 'inModal' | 'errors' | 'tempState' | 'onChangeEmail' | 'disabled'
  > {}

const EmailSettingsBlock = <T extends ExtendedState>({
  disabled,
  errors,
  inModal,
  isPreFetching,
  onChangeEmail,
  tempState,
}: EmailSettingsBlockProps<T>) => {
  const { t } = useLocoTranslation()
  const { data: options } = useEmailOptions()

  return (
    <DisclosureBox
      wrapperClassName={twJoin(inModal && 'border border-gray/30 rounded-lg')}
      defaultOpen
      title={
        <b className={`text-start text-darkblue text-[17px]`}>
          {t('dashboard.email.email_settings')}
        </b>
      }
    >
      <div className={`gap-5 flex flex-col`}>
        <FormInput
          disabled={disabled}
          isPreFetching={isPreFetching || !options}
          value={tempState?.fromName || ''}
          onChange={e => tempState && onChangeEmail({ ...tempState, fromName: e.target.value })}
          error={errors.fromName}
          required
          label={t('dashboard.email.sender_name')}
        />
        {!!tempState?.fromEmail && isBlacklistEmailDomain(tempState?.fromEmail) && (
          <SectionMessage>{t('dashboard.email.new_google_policy_warning_message')}</SectionMessage>
        )}
        <SenderEmailSelect
          value={tempState?.fromEmail || ''}
          onChange={value => tempState && onChangeEmail({ ...tempState, fromEmail: value })}
          error={errors.fromEmail}
          required
          disabled={disabled}
          isPreFetching={isPreFetching}
        />
      </div>
    </DisclosureBox>
  )
}

export default EmailSettingsBlock
