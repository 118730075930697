import { useCallback, useRef } from 'react'
import useSWR, { BareFetcher, Key, SWRConfiguration } from 'swr'

export const useControlledSwr = <Data = any, Error = any>(
  key: Key,
  fetcher: BareFetcher<Data>,
  config?: SWRConfiguration<Data, Error, BareFetcher<Data>>,
  isFetchAllowed: boolean = true,
) => {
  const aborter = useRef<AbortController>()
  const abort = useCallback(() => aborter.current?.abort(), [])

  const swrData = useSWR<Data>(
    isFetchAllowed ? key : undefined,
    args => {
      const url = Array.isArray(args) ? args[0] : args
      aborter.current = new AbortController()
      return fetcher?.(url, { signal: aborter.current.signal })
    },
    config,
  )

  return { ...swrData, abort }
}
